import shopifySparkV1Defaults from '../shopify-spark-v1/config.js';

// client asked to add the FacetPanel widget to https://grudgemotorsports.com/pages/shop-by-brand and https://grudgemotorsports.com/collections pages.
// since the FacetTiles widget sends 'facets.json' request, not 'search.json', we need to tell the FacetPanel
// that it can send the initial search request for itself
shopifySparkV1Defaults.Widgets.find((w) => w.name === 'CategoryFacetPanel').needInitRequest = true;

export default {
  includes: ['shopify-spark-v1'],
  ...shopifySparkV1Defaults,
};
