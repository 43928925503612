//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-192:_1332,_8588,_6708,_896,_6336,_8900,_2000,_1764;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-192')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-192', "_1332,_8588,_6708,_896,_6336,_8900,_2000,_1764");
        }
      }catch (ex) {
        console.error(ex);
      }